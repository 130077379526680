<app-toolbar>
  <ng-content ngProjectAs="menu">
    @if (isAdmin) {
      <button mat-menu-item (click)="createRobot()">Create Robot</button>
      <button mat-menu-item (click)="createVirtualRobot()">
        Create Virtual Robot
      </button>
    }
  </ng-content>
</app-toolbar>

@if (dataSource.data.length === 0) {
  <div class="spinner-background">
    <mat-spinner />
  </div>
}

<div class="container" [class.hidden]="dataSource.data.length === 0">
  <div class="filters-container">
    <app-robot-filters
      [accessGroups]="accessGroups"
      [operationIds]="operationIds"
      (onFilterChange)="applyFilter($event)"
    />
    <div class="robot-actions">
      @if (canSuperviseRobots) {
        <button
          mat-raised-button
          color="primary"
          [disabled]="selection.selected.length === 0"
          (click)="superviseRobots()"
          class="action-button-supervise-robots"
        >
          Supervise {{ filteredAndSelectedRobot().length }} robots
          <mat-icon>launch</mat-icon>
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="selection.selected.length === 0"
          (click)="openMassAction()"
          matTooltip="Open mass action dialog"
        >
          Open Actions
        </button>
      }
      <mat-paginator
        [pageSize]="25"
        [hidePageSize]="true"
        aria-label="Select page"
        class="paginator"
        (page)="scrollRobotTableUp()"
      />
    </div>
  </div>

  <div #tableScrollContainer class="robot-table">
    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      matSort
      matSortActive="updatedAt"
      matSortDirection="desc"
      matSortDisableClear
      [trackBy]="trackByRobot"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? toggleFilteredSelection() : null"
            [checked]="isAllFilteredSelected()"
            [indeterminate]="
              filteredAndSelectedRobot().length > 0 && !isAllFilteredSelected()
            "
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let robot">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(robot.id) : null"
            [checked]="selection.isSelected(robot.id)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="picture">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let robot">
          @if (robot.pictureUrl) {
            <img
              src="{{ robot.pictureUrl }}"
              class="google-profile-img"
              [ngStyle]="{
                'background-color': robot.displayColor
                  ? robot.displayColor
                  : '#f8961d',
              }"
            />
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="robotView">
        <th mat-header-cell *matHeaderCellDef>Robot View</th>
        <td mat-cell *matCellDef="let robot">
          <a href="/robots/supervise/{{ robot.id }}" target="_blank">
            @if (robot.robotView) {
              <img [src]="transform(robot.robotView)" class="robot-view" />
            }
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc">
          Sv.
        </th>
        <td mat-cell *matCellDef="let robot">
          @if (robot.issues !== undefined && robot.issues.length > 0) {
            <div class="status-circle {{ extractRobotStatus(robot) }}">
              <app-robot-issues-popover
                [robot]="robot"
                [issues]="robot.issues"
              />
              {{ robot.issues | length }}
            </div>
          } @else {
            <div class="status-circle {{ extractRobotStatus(robot) }}"></div>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="batteryPercentage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc">
          Battery
        </th>
        <td mat-cell *matCellDef="let robot">
          <app-battery-status
            [batteryPercentage]="robot.batteryPercentage"
            [charging]="robot.isCharging"
          />
        </td>
      </ng-container>
      <ng-container matColumnDef="serialNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Robot Name</th>
        <td mat-cell *matCellDef="let robot">
          {{ robot.renderedName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="assignedOperationId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Assigned Operation
        </th>
        <td mat-cell *matCellDef="let robot" id="assigned-operation-id">
          {{ robot.assignedOperationId || '-' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="readyForOrders">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc">
          Ready
        </th>
        <td mat-cell *matCellDef="let robot" id="ready-for-orders">
          <mat-slide-toggle
            [ngModel]="robot.readyForOrders"
            (change)="toggleRobotReadyForOrder(robot)"
          />
        </td>
      </ng-container>
      <ng-container matColumnDef="assignedOperator">
        <th mat-header-cell *matHeaderCellDef>Assigned Operator</th>
        <td mat-cell *matCellDef="let robot"></td>
      </ng-container>
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc">
          Last Seen
        </th>
        <td
          mat-cell
          *matCellDef="let robot"
          [matTooltip]="robot.updatedAtTooltip"
          matTooltipPosition="above"
        >
          {{ robot.updatedAt | prettyTime: 'date' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="awxUpdate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Updating</th>
        <td mat-cell *matCellDef="let robot">
          @if (robot.updateIcon) {
            <button
              mat-icon-button
              aria-label="Open robot update history dialog"
              matTooltip="Open robot update history"
              matTooltipPosition="above"
              (click)="openRobotAwxUpdateHistory(robot)"
            >
              <mat-icon>{{ robot.updateIcon }}</mat-icon>
            </button>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="connectivity">
        <th mat-header-cell *matHeaderCellDef>Connectivity</th>
        <td mat-cell *matCellDef="let robot">
          @if (robot.modem1Connected !== undefined) {
            <app-connectivity-status
              [modem1Connected]="robot.modem1Connected"
              [modem2Connected]="robot.modem2Connected"
              [modem3Connected]="robot.modem3Connected"
              [wifiConnected]="robot.wifiConnected"
            />
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="containerVersions">
        <th mat-header-cell *matHeaderCellDef>Container Versions</th>
        <td mat-cell *matCellDef="let robot">
          @for (
            version of getContainerVersionsString(robot.componentVersions);
            track $index
          ) {
            <span>{{ version }}<br /></span>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="action-buttons">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let robot">
          <div class="action-buttons-container">
            <a
              mat-icon-button
              color="primary"
              aria-label="Go to robot log"
              matTooltip="Robot Log"
              matTooltipPosition="above"
              [href]="getElasticRobotLogLink(robot.serialNumber)"
              target="blank"
            >
              <mat-icon>list</mat-icon>
            </a>
            <button
              mat-icon-button
              color="primary"
              aria-label="Access robot blackbox"
              matTooltip="Access robot blackbox"
              matTooltipPosition="above"
              (click)="showRobotBlackbox(robot)"
            >
              <mat-icon>movie</mat-icon>
            </button>
            <button
              mat-icon-button
              color="primary"
              aria-label="Edit robot"
              matTooltip="Edit robot"
              matTooltipPosition="above"
              (click)="editRobot(robot)"
            >
              <mat-icon>edit</mat-icon>
            </button>

            <button
              mat-icon-button
              aria-label="Open claim robot dialog"
              [matTooltip]="
                robot.isClaimed
                  ? 'Check who claims the robot'
                  : 'Claim the robot'
              "
              matTooltipPosition="above"
              (click)="openClaimRobotDialog(robot)"
            >
              <mat-icon
                [color]="robot.isClaimed === true ? 'accent' : 'primary'"
                >handyman</mat-icon
              >
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let robot; columns: columnsToDisplay"
        class="element-row"
        [class.offline]="(robot.updatedAt | prettyTime) !== 'just now'"
      ></tr>
      <!-- Row shown when there is no matching data. -->
      <ng-container matColumnDef="notfound">
        <td mat-footer-cell *matFooterCellDef colspan="4">
          No data matching the filter.
        </td>
      </ng-container>
      <tr
        mat-footer-row
        *matFooterRowDef="['notfound']"
        class="example-second-footer-row"
        [style.display]="
          dataSource.filteredData.length === 0 && dataSource.data.length > 0
            ? 'table-row'
            : 'none'
        "
      ></tr>
    </table>
  </div>
</div>
