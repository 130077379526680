<div
  class="robot-and-overlay"
  (mouseenter)="onMouseIn()"
  (mouseleave)="onMouseOut()"
  [class.full-screen]="showFullScreenPreview$ | async"
>
  <app-robot-control
    [isKeyboardEnabled]="isMouseHovering"
    [robotCommunication]="robotCommunication"
    [activeControl]="(showFullScreenPreview$ | async) !== true"
    [highResolution]="true"
    (needsAttention)="handleAttentionRequest('crossing-confirmation', $event)"
    [isMaintenanceMode]="false"
  />

  <button
    class="remove-slot-button"
    mat-mini-fab
    color="primary"
    (click)="clickRemoveRobot()"
    [matTooltip]="'Remove robot slot'"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<app-robot-control-panel
  [robotCommunication]="robotCommunication"
  [isClaimControlEnabled]="false"
  [isManualControlEnabled]="false"
  [autonomyControlType]="'PARKING_MODE'"
  [hazardLightControlType]="'HAZARD_LIGHT_TOGGLE'"
  [isNavigationToMaintenanceEnabled]="false"
  [isCurbClimbingControlEnabled]="false"
  [isReadyForOrderControlEnabled]="false"
  [isIssueReportingEnabled]="false"
  [active]="isMouseHovering"
  [isRelocalizeEnabled]="false"
  [isCalibrateEndstopEnabled]="false"
  [isNetworkInterfaceControlEnabled]="true"
  [isBlockageReportEnabled]="false"
  [isHandlingSnapshotHotkeyEnabled]="isMouseHovering"
>
  <button
    mat-mini-fab
    color="primary"
    (click)="emitFocusChange()"
    [matTooltip]="'Focus on the robot'"
  >
    <mat-icon>fullscreen</mat-icon>
  </button>

  <ng-content ngProjectAs="menu">
    <button mat-menu-item (click)="skipRobot()">
      <mat-icon>arrow_forward</mat-icon> Skip robot
    </button>
  </ng-content>
</app-robot-control-panel>
